<!--
 * @Description: 行政区域编码
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:56:58
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 14:33:02
-->
<template>
  <el-card class="box data">
    <el-table
      :data="provinces"
      v-model="loading"
      row-key="code"
      lazy
      :load="loadChildren"
      :tree-props="props"
      border
    >
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="行政区域编码" prop="code"></el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onExport(scope.row)"
            >开通</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-city :item="current" :list="tableData"></edit-city>
</template>
<script>
import EditCity from "./edit_city.vue";

export default {
  components: {
    EditCity,
  },
  data() {
    return {
      loading: false,
      provinces: [],
      props: { children: "children", hasChildren: "hasChildren" },
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadPro();
    this.loadData();
  },
  methods: {
    /**
     * 
     */
    loadData() {
      this.$http.get("/admin/v1/area").then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 获取省份数据
     */
    loadPro() {
      this.$http.get("admin/v1/regioncode/province").then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            item.hasChildren = true;
            item.children = [];
          });
          this.provinces = res.data;
        }
      });
    },
    /**
     * 加载子级
     */
    loadChildren(val, node, resolve) {
      this.$http
        .get("/admin/v1/regioncode/children?code=" + val.code)
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((item) => {
              item.hasChildren = this.isHasChild(item.code);
              item.children = [];
            });
            return resolve(res.data);
          }
        });
    },

    /**
     * 判断是否含有子级
     * @param {*} code
     */
    isHasChild(code) {
      var reg = /00$/i;
      return reg.test(code);
    },

    /**
     * 开通
     * @param {*} item
     */
    onExport(item) {
      console.log(item);
      this.current = {
        adcode: item.code,
        name: item.name,
        status: 1,
      };
    },
  },
};
</script>